import { GridColumnModel } from "./project-grid-columns";
export const projectOrderLineColumnStatus = "status";
export const projectOrderLineColumnCollaborator = "collaborator";
export const projectOrderLineColumnOrderLine = "orderLineNumber";
export const projectOrderLineColumnMyHours = "myHours";
export const projectOrderLineColumnTotalHours = "totalHours";
export const projectOrderLineColumns: GridColumnModel[] = [
  {
    id: "wbs",
    value: " ",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "70px",
    widthNum: 70,
    orderNo: 1,
    align: "center",
    isColumnNotReordable: true,
    isFlexWidth: false,
    orderNoShowHide: 0,
    canHide: false,
    frozenPermanent: true,
  },
  {
    id: projectOrderLineColumnOrderLine,
    value: "Order Line Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: true,
    width: "470px",
    widthNum: 470,
    orderNo: 2,
    isColumnNotReordable: true,
    isFlexWidth: false,
    orderNoShowHide: 1,
    canHide: false,
    frozenPermanent: true
  },
  {
    id: "projectNumber",
    value: "Project Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "120px",
    widthNum: 120,
    orderNo: 3,
    isFlexWidth: true,
    orderNoShowHide: 2,
    canHide: true,
  },
  {
    id: "orderNumber",
    value: "Order Number",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "120px",
    widthNum: 120,
    orderNo: 4,
    isFlexWidth: true,
    orderNoShowHide: 3,
    canHide: true,
  },
  {
    id: projectOrderLineColumnStatus,
    value: "FLEX Task Status",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "180px",
    widthNum: 180,
    orderNo: 5,
    isFlexWidth: true,
    orderNoShowHide: 4,
    canHide: false,
  },
  {
    id: projectOrderLineColumnCollaborator,
    value: "Contributors",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "250px",
    widthNum: 250,
    orderNo: 6,
    isFlexWidth: false,
    orderNoShowHide: 5,
    canHide: false,
  },
  {
    id: projectOrderLineColumnTotalHours,
    value: "Total Hours",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 100,
    orderNo: 7,
    isFlexWidth: true,
    orderNoShowHide: 6,
    canHide: true,
  },
  {
    id: projectOrderLineColumnMyHours,
    value: "My Hours",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 100,
    orderNo: 8,
    isFlexWidth: true,
    orderNoShowHide: 7,
    canHide: true,

  },
  {
    id: "duration",
    value: "Duration",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "100px",
    widthNum: 100,
    orderNo: 9,
    isFlexWidth: true,
    orderNoShowHide: 8,
    canHide: true,
  },
  {
    id: "startDate",
    value: "Start Date",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "115px",
    widthNum: 115,
    orderNo: 10,
    isFlexWidth: true,
    orderNoShowHide: 9,
    canHide: false,
  },
  {
    id: "dueDate",
    value: "Due Date",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "115px",
    widthNum: 115,
    orderNo: 11,
    isFlexWidth: true,
    orderNoShowHide: 10,
    canHide: false,
  },
  {
    id: "completionDate",
    value: "Completion Date",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "115px",
    widthNum: 115,
    orderNo: 12,
    isFlexWidth: true,
    orderNoShowHide: 11,
    canHide: true,
  },
];
