import clsx from "clsx";
import moment from "moment";
import {
  Calendar,
  CalendarChangeParams,
  CalendarDateTemplateParams,
} from "primereact/calendar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRecentMilestoneChanges,
  updateE2EProjectLineFlexTaskGroupItemsDates,
  updateMilestoneRecentChange,
  updateMilestonesFromEditMilestoneDateResult,
} from "../../../../features/projectManagement/projectManagementSlice";
import useToastr from "../../../../hooks/useToastr";
import MilestoneDateService from "../../../../services/MilestoneDateService";
import { E2EProjectLineECDAdjustmentModel } from "../../../../shared/models/service-models/E2EProjectLineECDAdjustmentModel";
import ECDAdjustmentsConfirmationDialog from "../../shared/components/ecd-adjustments-confirmation-dialog/ECDAdjustmentsConfirmationDialog";
import ProjectStatus from "../../shared/enums/project-status";
import PmrE2EProjectFlexTaskMilestonesGroup from "../../shared/interfaces/pmr-e2e-project-milestone-group";
import classes from "./MilestoneDateTemplate.module.scss";

type Props = {
  rowData: PmrE2EProjectFlexTaskMilestonesGroup;
  dateClassName: (index: number) => string;
  dateLabel: string;
  fieldName: string;
  isStartDate: boolean;
};

const calendarStartDate = new Date(2020, 0, 1);
const calendarEndDate = new Date(2030, 11, 31);

const MilestoneDateTemplate = ({
  rowData,
  dateClassName,
  dateLabel,
  isStartDate,
  fieldName,
}: Props) => {
  const dispatch = useDispatch();
  const [viewDate, setViewDate] = useState<Date | undefined>();

  const [editModeMilestoneId, setEditModeMilestoneId] = useState<
    string | null
  >();
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [newDate, setNewDate] = useState<Date | null>(null);
  const [
    eCDAdjustmentsConfirmationDialogVisible,
    setECDAdjustmentsConfirmationDialogVisible,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccess, showError } = useToastr();
  const [isECDAdjustmentsLoading, setIsECDAdjustmentsLoading] = useState(false);
  const [ecdAdjustments, setEcdAdjustments] =
    useState<E2EProjectLineECDAdjustmentModel | null>(null);
  const [isKeepECDDisabled, setIsKeepECDDisabled] = useState(false);
  const recentMilestoneChanges = useSelector(selectRecentMilestoneChanges);

  const isEditMode = (milestoneIndex: number) => {
    return editModeMilestoneId === rowData.id[milestoneIndex];
  };

  const setIsEditMode = (milestoneIndex: number, isEditMode: boolean) => {
    const milestoneId = rowData.id[milestoneIndex];
    setEditModeMilestoneId(isEditMode ? milestoneId : null);
  };

  const isEditable = (index: number) => {
    const milestoneStatus = rowData.status[index];

    return (
      milestoneStatus !== ProjectStatus.Completed &&
      milestoneStatus !== ProjectStatus.Cancelled
    );
  };

  const dateTooltip = (index: number, date: string) => {
    const milestoneStatus = rowData.status[index];

    if (milestoneStatus === ProjectStatus.Completed)
      return `${dateLabel} of a Completed milestone cannot be updated/edited.`;

    if (milestoneStatus === ProjectStatus.Cancelled)
      return "Editing start and due dates, logging work hours, and timestamping are disabled for cancelled tasks/milestones.";

    return date;
  };

  const dateTemplate = (e: CalendarDateTemplateParams) => {
    const date = new Date(e.year, e.month, e.day);
    const dayOfWeek = date.getDay();
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

    return (
      <span className={clsx(isWeekend && classes["not-allowed"])}>{e.day}</span>
    );
  };

  const openEditDateCalendar = async (milestoneIndex: number) => {
    if (!isEditable(milestoneIndex)) return;

    setIsCalendarVisible(false);
    setEditModeMilestoneId(null);

    const currentDate = rowData[fieldName][milestoneIndex];
    setIsEditMode(milestoneIndex, true);
    const newDate = new Date(currentDate);
    setNewDate(newDate);

    // Sets the view date into the first day of the month to address the issue linked below.
    // https://github.com/primefaces/primereact/issues/2948
    setViewDate(new Date(newDate.getFullYear(), newDate.getMonth(), 1));

    setTimeout(() => {
      setIsCalendarVisible(true);
    }, 100);
  };

  const ecdAdjustmentsTableHeader = (
    <>
      Editing the {dateLabel.toLowerCase()} will trigger a recalibration of the
      schedule and ECD.
      <br /> <br /> How would you like to proceed?
    </>
  );

  const handleConfirm = async (adjustECDProjectLineIds: string[]) => {
    setIsLoading(true);
    const parsedNewDate = moment(newDate).format("YYYY-MM-DD");

    try {
      const result = await MilestoneDateService.editDate({
        e2EProjectLineMilestoneId: editModeMilestoneId!,
        onlyFetchAdjustments: false,
        newStartDate: isStartDate ? parsedNewDate : null,
        newDueDate: !isStartDate ? parsedNewDate : null,
        isAdjustECD: adjustECDProjectLineIds.length > 0,
      });

      if (!result.isSuccess) return showError("Error", result.message);

      const message = `${dateLabel} for <b>'${result.data.milestoneName}'</b> under order line <b>'${result.data.orderLineDescription}'</b> has been successfully updated. Schedules has been adjusted accordingly.`;
      showSuccess("Success!", message);
      setECDAdjustmentsConfirmationDialogVisible(false);
      setEditModeMilestoneId(null);

      dispatch(
        updateMilestonesFromEditMilestoneDateResult(
          result.data.updatedMilestones
        )
      );
      dispatch(
        updateE2EProjectLineFlexTaskGroupItemsDates(result.data.updatedTasks)
      );
      dispatch(updateMilestoneRecentChange(result.data.changes));
    } catch (ex: any) {
      showError(
        "Error",
        "There is an error processing your request. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewDateChange = async (e: CalendarChangeParams) => {
    const selectedNewDate = e.value as Date;

    if (
      moment(selectedNewDate).format("YYYY-MM-DD") ===
      moment(newDate).format("YYYY-MM-DD")
    ) {
      setIsCalendarVisible(false);
      setEditModeMilestoneId(null);
      return;
    }

    setNewDate(selectedNewDate);
    setIsECDAdjustmentsLoading(true);
    setECDAdjustmentsConfirmationDialogVisible(true);
    const parsedNewDate = moment(selectedNewDate).format("YYYY-MM-DD");

    const ecdAdjustments = await MilestoneDateService.editDate({
      e2EProjectLineMilestoneId: editModeMilestoneId!,
      onlyFetchAdjustments: true,
      newStartDate: isStartDate ? parsedNewDate : null,
      newDueDate: !isStartDate ? parsedNewDate : null,
      isAdjustECD: false,
    });

    setEcdAdjustments({
      adjustedECD: ecdAdjustments.data.adjustedECD,
      currentECD: ecdAdjustments.data.oldECD,
      e2EProjectLineId: ecdAdjustments.data.e2EProjectLineId,
      orderlineDescription: ecdAdjustments.data.orderLineDescription,
      e2EProjectName: ecdAdjustments.data.e2EProjectName,
    });

    setIsECDAdjustmentsLoading(false);
    setIsKeepECDDisabled(ecdAdjustments.data.canKeepECD === false);
  };

  const isRecentlyChanged = (milestoneIndex: number, fieldName: string) => {
    const milestoneId = rowData.id[milestoneIndex];

    return recentMilestoneChanges.some(
      (change) =>
        change.e2EProjectLineMilestoneId === milestoneId &&
        change.fieldChanged === fieldName
    );
  };

  return (
    <>
      {rowData[fieldName].map((date: string, index: number) => {
        const render = isEditMode(index) ? (
          <Calendar
            viewDate={viewDate}
            onViewDateChange={(e) => setViewDate(e.value)}
            disabledDays={[0, 6]}
            className={classes["calendar"]}
            dateTemplate={dateTemplate}
            dateFormat="dd M yy"
            visible={isCalendarVisible}
            onVisibleChange={(e) => {
              if (e.visible) setIsCalendarVisible(true);
              if (
                e.type !== "dateselect" &&
                !eCDAdjustmentsConfirmationDialogVisible
              )
                setIsCalendarVisible(false);
            }}
            value={newDate!}
            onChange={handleNewDateChange}
            monthNavigator={true}
            yearNavigator={true}
            yearRange={`${calendarStartDate.getFullYear()}:${calendarEndDate.getFullYear()}`}
            panelClassName={classes["calendar-panel"]}
            onHide={() => setIsEditMode(index, false)}
          />
        ) : (
          <span
            className={clsx(
              isRecentlyChanged(
                index,
                isStartDate ? "FormattedStartDate" : "FormattedDueDate"
              ) && "recently-changed",
              dateClassName(index)
            )}
            onClick={() => openEditDateCalendar(index)}
          >
            {date}
          </span>
        );

        return (
          <div
            key={index}
            title={dateTooltip(index, date)}
            className={clsx(classes["start-date"], {
              [classes["not-allowed"]]: !isEditable(index),
              [classes["edit-mode"]]: isEditMode(index),
            })}
            style={{
              borderBottom:
                index + 1 !== rowData[fieldName].length ? "1px solid #ddd" : "",
              padding: "10px",
            }}
          >
            <span>{render}</span>
          </div>
        );
      })}

      <ECDAdjustmentsConfirmationDialog
        isAdjustECDDisabled={isLoading}
        isKeepECDDisabled={isKeepECDDisabled || isLoading}
        isECDAdjustmentsLoading={isECDAdjustmentsLoading}
        tableHeader={ecdAdjustmentsTableHeader}
        customModalHeader="Estimated Completion Date (ECD)"
        customConfirmationMessage="Do you want to proceed?"
        forceProjectNameColumnDisplay={true}
        e2eProjectLineECDAdjustments={
          ecdAdjustments === null ? [] : [ecdAdjustments]
        }
        visible={eCDAdjustmentsConfirmationDialogVisible}
        onCancel={() => setECDAdjustmentsConfirmationDialogVisible(false)}
        onConfirm={handleConfirm}
        isKeepButtonVisible={true}
        isCancelButtonVisible={false}
        keepButtonTooltip={
          isKeepECDDisabled
            ? "Your selected date will not meet the ECD. Please adjust ECD or choose another date."
            : ""
        }
      />
    </>
  );
};

export default MilestoneDateTemplate;
